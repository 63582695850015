
// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat-core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$revogo-frontend-primary: mat-palette($mat-teal);
$revogo-frontend-accent: mat-palette($mat-orange, A200, A100, A400);

// The warn palette is optional (defaults to red).
$revogo-frontend-warn: mat-palette($mat-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$revogo-frontend-theme: mat-light-theme((
  color: (
    primary: $revogo-frontend-primary,
    accent: $revogo-frontend-accent,
    warn: $revogo-frontend-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include angular-material-theme($revogo-frontend-theme);


@mixin mix-app-theme($app-theme) {
  $primary: map-get($app-theme, primary);
  $accent: map-get($app-theme, accent);

  .mat-button-toggle-checked {
    background-color: mat-color($primary);

    .mat-button-toggle-label-content {
      color: white;
    }
  }
}

// Include the mixin
@include mix-app-theme($revogo-frontend-theme);

html, body { height: 100%; }
body { margin: 0; font-family: Roboto, "Helvetica Neue", sans-serif; }
