/* You can add global styles to this file, and also import other style files */
@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

.error-snackbar {
  background-color: #cc4040;
  color: white;
}


$dark-text-color: #324B4E;

.horizontal-separator {
  width: 100%;
  height: 2px;
  background-color: black;
  border-radius: 2px;
}

.vertical-separator {
  height: 100%;
  width: 2px;
  background-color: black;
  border-radius: 2px;
}

.dark-text {
  color: $dark-text-color;
}

.primary-text {
  color: #56979F
}

.error-text {
  color: #ed7a28;
}

.session-action-button-container {
  height: 100%;

  button {
    height: 100%;
  }
}

.mat-expansion-panel-header-description, .mat-expansion-indicator::after {
  color:#56979F !important;
  border-color:#56979F !important;
}

.wide-modal {
  .mat-dialog-container {
    min-width: 80vw !important;
  }
}

.intrusive-tooltip {
  min-width: 50vw !important;
  font-size: large !important;
  background-color: #404040 !important;
}

input[type="number"]::-webkit-inner-spin-button {
  opacity: 1 !important;
}

.small-icon-button {
  width: 24px !important;
  height: 24px !important;
  line-height: 23px !important;
  margin-top: 4px !important;
}


.small-button {
  margin-top: 4px !important;
  line-height: 23px !important;
  height: 23px !important;
}

.message-thread-sort-select {
  max-width: 74px !important;
}
